<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <WeInput
              label="Tedarikçi İsmi (*)"
              name="supplier_name"
              v-bind:required="true"
              v-model="form.name"
              v-bind:error="$v.form.name.$error"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <label class="custom-label">Entegrasyon Türü</label>
            <v-select
              v-model="form.type"
              label="name"
              v-bind:options="supplier.types"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              class="setting-select"
              v-bind:required="true"
              v-bind:error="$v.form.type.$error"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group mb-3">
            <WeInput
              label="Entegrasyon Adresi (Url)"
              name="supplier_address"
              v-bind:required="true"
              v-model="form.address"
              v-bind:error="$v.form.address.$error"
            />
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="custom-label">Varsayılan Para Birimi (*)</label>
            <v-select
              label="name"
              v-bind:options="supplier.currencies"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              v-bind:required="true"
              v-model="form.currency"
              class="setting-select"
            />
          </div>
        </div>
         <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <WeInput
              label="Varsayılan Stok Miktarı"
              name="supplier_stock_quantity"
              v-bind:required="true"
              v-model="form.stock_quantity"
            />
          </div>
        </div>
        <div class="col-12 col-lg-12" v-if="form.type == 1">
          <div class="form-group mb-3">
            <label class="custom-label">XML Şablonu Seç</label>
            <v-select
              v-model="form.template"
              label="name"
              v-bind:options="xmlTemplates"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              class="setting-select"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <WeInput
              label="Kullanıcı Adı"
              name="supplier_username"
              v-bind:required="false"
              v-model="form.username"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group mb-3">
            <WeInput
              label="Şifre"
              name="supplier_password"
              v-bind:required="false"
              v-model="form.password"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="custom-label">Durum</label>
            <WeSwitch v-model="form.is_active" label="Aktif" />
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="custom-label">Ana Tedarikçi</label>
            <WeSwitch v-model="form.is_main" label="Aktif" />
          </div>
        </div>
         <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="custom-label">Kategorileri Kaydet</label>
            <WeSwitch v-model="form.is_save_category" label="Aktif" />
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="custom-label">Stoğu Olmayan Ürünleri Aç</label>
            <WeSwitch v-model="form.is_stock_track" label="Aktif" />
          </div>
        </div>
      </div>
    </WeCard>
    <WeCard class="mt-3">
      <h5>Son İşlem Sonuçları</h5>
      <WeTimeLine v-bind:data="form.logs" />
      <WeCard class="alert-primary" v-if="form.logs.length <= 0">
        <i class="fas fa-info-circle"></i>
        <span> Şuanda bir sonuç bulunamadı!</span>
      </WeCard>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      xmlTemplates: [],
      form: {
        name: null,
        is_active: true,
        is_main: false,
        is_save_category: false,
        is_stock_track: false,
        username: null,
        password: null,
        currency: null,
        stock_quantity: null,
        address: null,
        rootElement: null,
        type: null,
        logs: [],
      },
      cleanData: {},
      cleanRequest: {},
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      address: {
        required,
      },
      type: {
        required,
      },
      currency: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("supplier", [
      "create",
      "update",
      "getById",
      "checkMain",
      "getCurrencyList",
    ]),
    ...mapActions("xmlTemplate", ["getList"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      if (this.form.is_main) {
        this.checkMainSupplier();
      } else {
        this.sendSubmitForm();
      }
    },
    sendSubmitForm() {
      if (this.$route.params.id) {
        this.cleanRequest = this.form;
        this.updateSupplier();
      } else {
        this.addSupplier();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Tedarikçi Entegrasyonları listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addSupplier() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateSupplier() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getXmlTemplate() {
      this.getList({
        lang: this.tmpLang,
        onSuccess: (result) => {
          this.xmlTemplates = result.data.items;
        },
      });
    },
    getCurrency() {
      this.getCurrencyList({});
    },
    checkMainSupplier() {
      this.checkMain({
        onSuccess: (result) => {
          if (result.data.data.length > 0) {
            this.$swal({
              title: "Uyarı",
              text:
                result.data.data[0].name +
                " adında bir tedarikçi zaten Ana Tedarikçi olarak seçilmiş. Devam edersen yeni ana tedarikçin " +
                this.form.name +
                " olacak.",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Vazgeç",
              confirmButtonText: "Onayla",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.sendSubmitForm();
              }
            });
          } else {
            this.sendSubmitForm();
          }
        },
      });
    },
    getSupplierById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    setChanged() {
      sessionStorage.setItem("changed", true);
    },
    removeChanged() {
      sessionStorage.removeItem("changed");
    },
    redirectToList() {
      this.$router.push("/supplier-integration");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["supplier", "shared", "session"]),
    getTitle() {
      return this.$route.params.id
        ? "Tedarikçi Entegrasyonu Düzenle"
        : "Tedarikçi Entegrasyonu Ekle";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  mounted() {
    this.getCurrency();
    this.getXmlTemplate();
    this.getSupplierById();
  },
};
</script>
